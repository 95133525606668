import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';

import { DateTimeWidgetWithClear } from '../common';
import { VaspServerStatus } from '../../constants';

function FilterDialog({
  open,
  setOpen,
  onSubmit,
  pendingOnVasp,
  pendingOnAccManager,
  pendingOnApprover,
  approved,
  merged,
  inactive,
  expired,
  fromDate,
  toDate,
  expiredFromDate,
  expiredToDate,
  createOperation,
  updateOperation,
  deleteOperation,
  returnOperation,
  resendOperation,
  sygnaBridge,
  sygnaGate,
  sygnaHub,
  sygnaEmailProtocol,
  serverStatusUnknown,
  serverStatusHealthy,
  serverStatusUnhealthy,
}) {
  const pendingOnVaspResult = pendingOnVasp.get;
  const pendingOnAccManagerResult = pendingOnAccManager.get;
  const pendingOnApproverResult = pendingOnApprover.get;
  const approvedResult = approved.get;
  const mergedResult = merged.get;
  const inactiveResult = inactive.get;
  const expiredResult = expired.get;

  const fromDateResult = fromDate.get;
  const toDateResult = toDate.get;
  const expiredFromDateResult = expiredFromDate.get;
  const expiredToDateResult = expiredToDate.get;
  const createOperationResult = createOperation.get;
  const updateOperationResult = updateOperation.get;
  const deleteOperationResult = deleteOperation.get;
  const returnOperationResult = returnOperation.get;
  const resendOperationResult = resendOperation.get;

  const sygnaBridgeResult = sygnaBridge.get;
  const sygnaGateResult = sygnaGate.get;
  const sygnaHubResult = sygnaHub.get;
  const sygnaEmailProtocolResult = sygnaEmailProtocol.get;

  const serverStatusUnknownResult = serverStatusUnknown.get;
  const serverStatusHealthyResult = serverStatusHealthy.get;
  const serverStatusUnhealthyResult = serverStatusUnhealthy.get;

  const [pendingOnVaspCheckbox, setPendingOnVaspCheckbox] = useState(pendingOnVaspResult);
  const [pendingOnAccManagerCheckbox, setPendingOnAccManagerCheckbox] =
    useState(pendingOnAccManagerResult);
  const [pendingOnApproverCheckbox, setPendingOnApproverCheckbox] =
    useState(pendingOnApproverResult);
  const [approvedCheckbox, setApprovedCheckbox] = useState(approvedResult);
  const [mergedCheckbox, setMergedCheckbox] = useState(mergedResult);
  const [inactiveCheckbox, setInactiveCheckbox] = useState(inactiveResult);
  const [expiredCheckbox, setExpiredCheckbox] = useState(expiredResult);

  const [selectedFromDate, setSelectedFromDate] = useState(fromDateResult);
  const [selectedToDate, setSelectedToDate] = useState(toDateResult);
  const [selectedExpiredFromDate, setSelectedExpiredFromDate] = useState(expiredFromDateResult);
  const [selectedExpiredToDate, setSelectedExpiredToDate] = useState(expiredToDateResult);

  const [createCheckbox, setCreateCheckbox] = useState(createOperationResult);
  const [updateCheckbox, setUpdateCheckbox] = useState(updateOperationResult);
  const [deleteCheckbox, setDeleteCheckbox] = useState(deleteOperationResult);
  const [returnCheckbox, setReturnCheckbox] = useState(returnOperationResult);
  const [resendCheckbox, setResendCheckbox] = useState(resendOperationResult);

  const [sygnaBridgeCheckbox, setBridgeCheckbox] = useState(sygnaBridgeResult);
  const [sygnaGateCheckbox, setGateCheckbox] = useState(sygnaGateResult);
  const [sygnaHubCheckbox, setHubCheckbox] = useState(sygnaHubResult);
  const [sygnaEmailProtocolCheckbox, setEmailProtocolCheckbox] = useState(sygnaEmailProtocolResult);

  const [serverStatusUnknownCheckbox, setServerStatusUnknownCheckbox] =
    useState(serverStatusUnknownResult);
  const [serverStatusHealthyCheckbox, setServerStatusHealthyCheckbox] =
    useState(serverStatusHealthyResult);
  const [serverStatusUnhealthyCheckbox, setServerStatusUnhealthyCheckbox] = useState(
    serverStatusUnhealthyResult,
  );

  useEffect(() => {
    if (pendingOnVaspCheckbox !== pendingOnVaspResult) {
      setPendingOnVaspCheckbox(pendingOnVaspResult);
    }
    if (pendingOnAccManagerCheckbox !== pendingOnAccManagerResult) {
      setPendingOnAccManagerCheckbox(pendingOnAccManagerResult);
    }
    if (pendingOnApproverCheckbox !== pendingOnApproverResult) {
      setPendingOnApproverCheckbox(pendingOnApproverResult);
    }
    if (approvedCheckbox !== approvedResult) {
      setApprovedCheckbox(approvedResult);
    }
    if (mergedCheckbox !== mergedResult) {
      setMergedCheckbox(mergedResult);
    }
    if (inactiveCheckbox !== inactiveResult) {
      setInactiveCheckbox(inactiveResult);
    }
    if (expiredCheckbox !== expiredResult) {
      setExpiredCheckbox(expiredResult);
    }
    if (selectedFromDate !== fromDateResult) {
      setSelectedFromDate(fromDateResult);
    }
    if (selectedToDate !== toDateResult) {
      setSelectedToDate(toDateResult);
    }
    if (selectedExpiredFromDate !== expiredFromDateResult) {
      setSelectedExpiredFromDate(expiredFromDateResult);
    }
    if (selectedExpiredToDate !== expiredToDateResult) {
      setSelectedExpiredToDate(expiredToDateResult);
    }
    if (createCheckbox !== createOperationResult) {
      setCreateCheckbox(createOperationResult);
    }
    if (updateCheckbox !== updateOperationResult) {
      setUpdateCheckbox(updateOperationResult);
    }
    if (deleteCheckbox !== deleteOperationResult) {
      setDeleteCheckbox(deleteOperationResult);
    }
    if (returnCheckbox !== returnOperationResult) {
      setReturnCheckbox(returnOperationResult);
    }
    if (resendCheckbox !== resendOperationResult) {
      setResendCheckbox(resendOperationResult);
    }
    if (sygnaBridgeCheckbox !== sygnaBridgeResult) {
      setBridgeCheckbox(sygnaBridgeResult);
    }
    if (sygnaGateCheckbox !== sygnaGateResult) {
      setGateCheckbox(sygnaGateResult);
    }
    if (sygnaHubCheckbox !== sygnaHubResult) {
      setHubCheckbox(sygnaHubResult);
    }
    if (sygnaEmailProtocolCheckbox !== sygnaEmailProtocolResult) {
      setEmailProtocolCheckbox(sygnaEmailProtocolResult);
    }
    if (serverStatusHealthyCheckbox !== serverStatusHealthyResult) {
      setServerStatusHealthyCheckbox(serverStatusHealthyResult);
    }
    if (serverStatusUnhealthyCheckbox !== serverStatusUnhealthyResult) {
      setServerStatusUnhealthyCheckbox(serverStatusUnhealthyResult);
    }
    if (serverStatusUnknownCheckbox !== serverStatusUnknownResult) {
      setServerStatusUnknownCheckbox(serverStatusUnknownResult);
    }
  }, [
    pendingOnVaspResult,
    pendingOnAccManagerResult,
    pendingOnApproverResult,
    approvedResult,
    mergedResult,
    inactiveResult,
    expiredResult,
    fromDateResult,
    toDateResult,
    expiredFromDateResult,
    expiredToDateResult,
    createOperationResult,
    updateOperationResult,
    deleteOperationResult,
    returnOperationResult,
    resendOperationResult,
    sygnaBridgeResult,
    sygnaGateResult,
    sygnaHubResult,
    sygnaEmailProtocolResult,
    serverStatusHealthyResult,
    serverStatusUnhealthyResult,
    serverStatusUnknownResult,
  ]);

  const handleSubmit = (e) => {
    if (pendingOnVasp.get !== pendingOnVaspCheckbox) {
      pendingOnVasp.set(pendingOnVaspCheckbox);
    }
    if (pendingOnAccManager.get !== pendingOnAccManagerCheckbox) {
      pendingOnAccManager.set(pendingOnAccManagerCheckbox);
    }
    if (pendingOnApprover.get !== pendingOnApproverCheckbox) {
      pendingOnApprover.set(pendingOnApproverCheckbox);
    }
    if (approved.get !== approvedCheckbox) {
      approved.set(approvedCheckbox);
    }
    if (merged.get !== mergedCheckbox) {
      merged.set(mergedCheckbox);
    }
    if (inactive.get !== inactiveCheckbox) {
      inactive.set(inactiveCheckbox);
    }
    if (expired.get !== expiredCheckbox) {
      expired.set(expiredCheckbox);
    }
    if (fromDate.get !== selectedFromDate) {
      fromDate.set(selectedFromDate);
    }
    if (toDate.get !== selectedToDate) {
      toDate.set(selectedToDate);
    }
    if (expiredFromDate.get !== selectedExpiredFromDate) {
      expiredFromDate.set(selectedExpiredFromDate);
    }
    if (expiredToDate.get !== selectedExpiredToDate) {
      expiredToDate.set(selectedExpiredToDate);
    }
    if (createOperation.get !== createCheckbox) {
      createOperation.set(createCheckbox);
    }
    if (updateOperation.get !== updateCheckbox) {
      updateOperation.set(updateCheckbox);
    }
    if (deleteOperation.get !== deleteCheckbox) {
      deleteOperation.set(deleteCheckbox);
    }
    if (returnOperation.get !== returnCheckbox) {
      returnOperation.set(returnCheckbox);
    }
    if (resendOperation.get !== resendCheckbox) {
      resendOperation.set(resendCheckbox);
    }

    if (sygnaBridge.get !== sygnaBridgeCheckbox) {
      sygnaBridge.set(sygnaBridgeCheckbox);
    }
    if (sygnaGate.get !== sygnaGateCheckbox) {
      sygnaGate.set(sygnaGateCheckbox);
    }
    if (sygnaHub.get !== sygnaHubCheckbox) {
      sygnaHub.set(sygnaHubCheckbox);
    }
    if (sygnaEmailProtocol.get !== sygnaEmailProtocolCheckbox) {
      sygnaEmailProtocol.set(sygnaEmailProtocolCheckbox);
    }

    if (serverStatusHealthy.get !== serverStatusHealthyCheckbox) {
      serverStatusHealthy.set(serverStatusHealthyCheckbox);
    }
    if (serverStatusUnhealthy.get !== serverStatusUnhealthyCheckbox) {
      serverStatusUnhealthy.set(serverStatusUnhealthyCheckbox);
    }
    if (serverStatusUnknown.get !== serverStatusUnknownCheckbox) {
      serverStatusUnknown.set(serverStatusUnknownCheckbox);
    }

    onSubmit(e);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Filter</DialogTitle>
      <DialogContent>
        <form method="post" onSubmit={onSubmit}>
          <Grid container direction="column" spacing={1}>
            <FormLabel>VASP Status</FormLabel>
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pendingOnVaspCheckbox}
                      onChange={(e) => setPendingOnVaspCheckbox(e.target.checked)}
                      value="pending on vasp"
                      color="primary"
                    />
                  }
                  label="pending on vasp"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pendingOnAccManagerCheckbox}
                      onChange={(e) => setPendingOnAccManagerCheckbox(e.target.checked)}
                      value="pending on account manager"
                      color="primary"
                    />
                  }
                  label="pending on account manager"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pendingOnApproverCheckbox}
                      onChange={(e) => setPendingOnApproverCheckbox(e.target.checked)}
                      value="pending on approver"
                      color="primary"
                    />
                  }
                  label="pending on approver"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={approvedCheckbox}
                      onChange={(e) => setApprovedCheckbox(e.target.checked)}
                      value="approved"
                      color="primary"
                    />
                  }
                  label="approved"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergedCheckbox}
                      onChange={(e) => setMergedCheckbox(e.target.checked)}
                      value="merged"
                      color="primary"
                    />
                  }
                  label="merged"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={inactiveCheckbox}
                      onChange={(e) => setInactiveCheckbox(e.target.checked)}
                      value="inactive"
                      color="primary"
                    />
                  }
                  label="inactive"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={expiredCheckbox}
                      onChange={(e) => setExpiredCheckbox(e.target.checked)}
                      value="expired"
                      color="primary"
                    />
                  }
                  label="expired"
                />
              </Grid>
            </Grid>

            <FormLabel style={{ marginTop: '1em' }}>Operation</FormLabel>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createCheckbox}
                      onChange={(e) => setCreateCheckbox(e.target.checked)}
                      value="create"
                      color="primary"
                    />
                  }
                  label="create"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={updateCheckbox}
                      onChange={(e) => setUpdateCheckbox(e.target.checked)}
                      value="update"
                      color="primary"
                    />
                  }
                  label="update"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={deleteCheckbox}
                      onChange={(e) => setDeleteCheckbox(e.target.checked)}
                      value="delete"
                      color="primary"
                    />
                  }
                  label="delete"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={returnCheckbox}
                      onChange={(e) => setReturnCheckbox(e.target.checked)}
                      value="return"
                      color="primary"
                    />
                  }
                  label="return"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={resendCheckbox}
                      onChange={(e) => setResendCheckbox(e.target.checked)}
                      value="resend"
                      color="primary"
                    />
                  }
                  label="resend"
                />
              </Grid>
            </Grid>
            <FormLabel style={{ marginTop: '1em' }}>Registration Type</FormLabel>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sygnaBridgeCheckbox}
                      onChange={(e) => setBridgeCheckbox(e.target.checked)}
                      value="sygna bridge"
                      color="primary"
                    />
                  }
                  label="sygna bridge"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sygnaGateCheckbox}
                      onChange={(e) => setGateCheckbox(e.target.checked)}
                      value="sygna gate"
                      color="primary"
                    />
                  }
                  label="sygna gate"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sygnaHubCheckbox}
                      onChange={(e) => setHubCheckbox(e.target.checked)}
                      value="sygna hub"
                      color="primary"
                    />
                  }
                  label="sygna hub"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sygnaEmailProtocolCheckbox}
                      onChange={(e) => setEmailProtocolCheckbox(e.target.checked)}
                      value="sygna emailprotocol"
                      color="primary"
                    />
                  }
                  label="sygna emailprotocol"
                />
              </Grid>
            </Grid>
            <FormLabel style={{ marginTop: '1em' }}>VASP server health status</FormLabel>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={serverStatusHealthyCheckbox}
                      onChange={(e) => setServerStatusHealthyCheckbox(e.target.checked)}
                      value={VaspServerStatus.HEALTHY}
                      color="primary"
                    />
                  }
                  label={VaspServerStatus.HEALTHY}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={serverStatusUnhealthyCheckbox}
                      onChange={(e) => setServerStatusUnhealthyCheckbox(e.target.checked)}
                      value={VaspServerStatus.UNHEALTHY}
                      color="primary"
                    />
                  }
                  label={VaspServerStatus.UNHEALTHY}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={serverStatusUnknownCheckbox}
                      onChange={(e) => setServerStatusUnknownCheckbox(e.target.checked)}
                      value={VaspServerStatus.UNKNOWN}
                      color="primary"
                    />
                  }
                  label={VaspServerStatus.UNKNOWN}
                />
              </Grid>
            </Grid>
            <FormLabel style={{ marginTop: '1em' }}>Go-live timestemp</FormLabel>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item xs={6} style={{ marginTop: '1em' }}>
                <DateTimeWidgetWithClear
                  text="From Date"
                  setState={setSelectedFromDate}
                  fromState={selectedFromDate}
                  disableFuture={false}
                  disablePast={false}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: '1em' }}>
                <DateTimeWidgetWithClear
                  text="To Date"
                  setState={setSelectedToDate}
                  fromState={selectedToDate}
                  disableFuture={false}
                  disablePast={false}
                  minDate={selectedFromDate && selectedFromDate.toISOString()}
                />
              </Grid>
            </Grid>
            <FormLabel style={{ marginTop: '1em' }}>Account Expiration Date</FormLabel>
            <Grid container item direction="row" spacing={1} xs={12}>
              <Grid item xs={6} style={{ marginTop: '1em' }}>
                <DateTimeWidgetWithClear
                  text="Expired From Date"
                  setState={setSelectedExpiredFromDate}
                  fromState={selectedExpiredFromDate}
                  disableFuture={false}
                  disablePast={false}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: '1em' }}>
                <DateTimeWidgetWithClear
                  text="Expired To Date"
                  setState={setSelectedExpiredToDate}
                  fromState={selectedExpiredToDate}
                  disableFuture={false}
                  disablePast={false}
                  minDate={selectedExpiredFromDate && selectedExpiredFromDate.toISOString()}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleSubmit}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
}

FilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pendingOnVasp: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnAccManager: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnApprover: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  approved: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  merged: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  inactive: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expired: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  fromDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  toDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expiredFromDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expiredToDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  createOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  updateOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  deleteOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  returnOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  resendOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaBridge: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaGate: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaHub: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaEmailProtocol: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusUnknown: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusHealthy: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusUnhealthy: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
};

export default FilterDialog;
