import React from 'react';
import PropTypes from 'prop-types';

import { Paper, Grid, IconButton, Chip, Tooltip } from '@material-ui/core';

import {
  EventRounded as EventIcon,
  FilterList as FilterIcon,
  CheckBox as CheckBoxIcon,
} from '@material-ui/icons';
import moment from 'moment';

function FilterDisplayer({
  onOpenFilterClicked,
  pendingOnVasp,
  pendingOnAccManager,
  pendingOnApprover,
  approved,
  merged,
  inactive,
  expired,
  fromDate,
  toDate,
  expiredFromDate,
  expiredToDate,
  createOperation,
  updateOperation,
  deleteOperation,
  returnOperation,
  resendOperation,
  sygnaBridge,
  sygnaGate,
  sygnaHub,
  sygnaEmailProtocol,
  serverStatusUnknown,
  serverStatusHealthy,
  serverStatusUnhealthy,
}) {
  return (
    <Paper>
      <Grid container item spacing={1} alignItems="center">
        <Grid item>
          <Tooltip title="filter">
            <IconButton onClick={onOpenFilterClicked}>
              <FilterIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          id="pending on vasp"
          style={{ display: pendingOnVasp.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="pending on vasp"
            onDelete={() => pendingOnVasp.set(false)}
          />
        </Grid>
        <Grid
          item
          id="pending on account manager"
          style={{ display: pendingOnAccManager.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="pending on account manager"
            onDelete={() => pendingOnAccManager.set(false)}
          />
        </Grid>
        <Grid
          item
          id="pending on approver"
          style={{ display: pendingOnApprover.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="pending on approver"
            onDelete={() => pendingOnApprover.set(false)}
          />
        </Grid>

        <Grid item id="approved" style={{ display: approved.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="approved" onDelete={() => approved.set(false)} />
        </Grid>
        <Grid item id="merged" style={{ display: merged.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="merged" onDelete={() => merged.set(false)} />
        </Grid>
        <Grid item id="inactive" style={{ display: inactive.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="inactive" onDelete={() => inactive.set(false)} />
        </Grid>
        <Grid item id="expired" style={{ display: expired.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="expired" onDelete={() => expired.set(false)} />
        </Grid>

        <Grid item id="create" style={{ display: createOperation.get ? 'inline-flex' : 'none' }}>
          <Chip
            icon={<CheckBoxIcon />}
            label="create"
            onDelete={() => createOperation.set(false)}
          />
        </Grid>

        <Grid item id="update" style={{ display: updateOperation.get ? 'inline-flex' : 'none' }}>
          <Chip
            icon={<CheckBoxIcon />}
            label="update"
            onDelete={() => updateOperation.set(false)}
          />
        </Grid>

        <Grid item id="delete" style={{ display: deleteOperation.get ? 'inline-flex' : 'none' }}>
          <Chip
            icon={<CheckBoxIcon />}
            label="delete"
            onDelete={() => deleteOperation.set(false)}
          />
        </Grid>

        <Grid item id="return" style={{ display: returnOperation.get ? 'inline-flex' : 'none' }}>
          <Chip
            icon={<CheckBoxIcon />}
            label="return"
            onDelete={() => returnOperation.set(false)}
          />
        </Grid>

        <Grid item id="resend" style={{ display: resendOperation.get ? 'inline-flex' : 'none' }}>
          <Chip
            icon={<CheckBoxIcon />}
            label="resend"
            onDelete={() => resendOperation.set(false)}
          />
        </Grid>

        <Grid item id="sygna_bridge" style={{ display: sygnaBridge.get ? 'inline-flex' : 'none' }}>
          <Chip
            icon={<CheckBoxIcon />}
            label="Sygna Bridge"
            onDelete={() => sygnaBridge.set(false)}
          />
        </Grid>
        <Grid item id="sygna_gate" style={{ display: sygnaGate.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="Sygna Gate" onDelete={() => sygnaGate.set(false)} />
        </Grid>
        <Grid item id="sygna_hub" style={{ display: sygnaHub.get ? 'inline-flex' : 'none' }}>
          <Chip icon={<CheckBoxIcon />} label="Sygna Hub" onDelete={() => sygnaHub.set(false)} />
        </Grid>
        <Grid
          item
          id="sygna_emailprotocol"
          style={{ display: sygnaEmailProtocol.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="Sygna EmailProtocol"
            onDelete={() => sygnaEmailProtocol.set(false)}
          />
        </Grid>
        <Grid
          item
          id="server_status_healthy"
          style={{ display: serverStatusHealthy.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="Healthy"
            onDelete={() => serverStatusHealthy.set(false)}
          />
        </Grid>
        <Grid
          item
          id="server_status_healthy"
          style={{ display: serverStatusUnhealthy.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="Unhealthy"
            onDelete={() => serverStatusUnhealthy.set(false)}
          />
        </Grid>
        <Grid
          item
          id="server_status_healthy"
          style={{ display: serverStatusUnknown.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<CheckBoxIcon />}
            label="Unknown"
            onDelete={() => serverStatusUnknown.set(false)}
          />
        </Grid>
        <Grid
          item
          id="date"
          style={{ display: fromDate.get || toDate.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<EventIcon />}
            label={`${fromDate.get ? moment(fromDate.get).format('YYYY/MM/DD HH:mm') : ''} ~ ${
              toDate.get ? moment(toDate.get).format('YYYY/MM/DD HH:mm') : ''
            }`}
            onDelete={() => {
              if (fromDate.get) {
                fromDate.set(null);
              }
              if (toDate.get) {
                toDate.set(null);
              }
            }}
          />
        </Grid>
        <Grid
          item
          id="expired_date"
          style={{ display: expiredFromDate.get || expiredToDate.get ? 'inline-flex' : 'none' }}
        >
          <Chip
            icon={<EventIcon />}
            label={`${
              expiredFromDate.get ? moment(expiredFromDate.get).format('YYYY/MM/DD HH:mm') : ''
            } ~ ${expiredToDate.get ? moment(expiredToDate.get).format('YYYY/MM/DD HH:mm') : ''}`}
            onDelete={() => {
              if (expiredFromDate.get) {
                expiredFromDate.set(null);
              }
              if (expiredToDate.get) {
                expiredToDate.set(null);
              }
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

FilterDisplayer.propTypes = {
  onOpenFilterClicked: PropTypes.func.isRequired,
  pendingOnVasp: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnAccManager: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnApprover: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  approved: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  merged: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  inactive: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expired: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  fromDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  toDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expiredFromDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expiredToDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  createOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  updateOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  deleteOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  returnOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  resendOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaBridge: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaGate: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaHub: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaEmailProtocol: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusHealthy: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusUnhealthy: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusUnknown: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
};

export default FilterDisplayer;
