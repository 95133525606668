import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

function ApproveTableHead(props) {
  const { headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const renderTableCell = (headCell) => {
    if (headCell.id === 'icons') {
      return (
        <TableCell key={headCell.id} padding="checkbox" width={headCell.width}>
          <Checkbox
            id="select_all_vasp_checkbox"
            disabled={rowCount === 0}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all vasp' }}
          />
        </TableCell>
      );
    }
    return (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? 'right' : 'left'}
        sortDirection={orderBy === headCell.id ? order : false}
        padding={headCell.disablePadding ? 'none' : 'default'}
        width={headCell.width}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={order}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      </TableCell>
    );
  };
  return (
    <TableHead>
      <TableRow>{headCells.map((headCell) => renderTableCell(headCell))}</TableRow>
    </TableHead>
  );
}

ApproveTableHead.propTypes = {
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      disablePadding: PropTypes.bool,
      width: PropTypes.string,
    }),
  ).isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default ApproveTableHead;
