import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import * as l from 'lodash/core';
import Layout from '../components/layout';
import Navigator from '../components/navigator';
import { CustomDialog, LoadingButton, ErrorDialog } from '../components/common';
import AuthenticatedPage from '../components/authenticated-page';
import {
  navigate,
  getVaspCode,
  checkJWTExpired,
  parseGraphQLErrorMessage,
  getToken,
  getRoleId,
  getRegistrationType,
} from '../utils/utils';

import { ApproveTable, FilterDialog } from '../components/approve-list';
import { GET_REVIEW_LIST, REVIEW_VASP, VALID_FORM } from '../crud';
import {
  OperationStatus,
  MIN_FROM_DATE,
  MAX_TO_DATE,
  Operations,
  RoleId,
  OperationRegistrationType,
  VaspServerStatus,
} from '../constants';
import { reviewVasp, isVaspCheckable } from '../components/approve-list/utils';

function ApproveVASPList() {
  const token = getToken();
  const roleId = getRoleId(token);

  const [reviewList, setReviewList] = useState([]);
  const [selectedVaspIds, setSelectedVaspIds] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [pendingOnVasp, setPendingOnVasp] = useState(false);
  const [pendingOnAccManager, setPendingOnAccManager] = useState(roleId === RoleId.ACCOUNT_MANAGER);
  const [pendingOnApprover, setPendingOnApprover] = useState(roleId === RoleId.ADMIN);
  const [approved, setApproved] = useState(false);
  const [merged, setMerged] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [expired, setExpired] = useState(false);
  const [selectedFromDate, setFromDate] = useState(null);
  const [selectedToDate, setToDate] = useState(null);
  const [selectedExpiredFromDate, setExpiredFromDate] = useState(null);
  const [selectedExpiredToDate, setExpiredToDate] = useState(null);

  const [createOperation, setCreateOperation] = useState(false);
  const [updateOperation, setUpdateOperation] = useState(false);
  const [deleteOperation, setDeleteOperation] = useState(false);
  const [returnOperation, setReturnOperation] = useState(false);
  const [resendOperation, setResendOperation] = useState(false);

  const [sygnaBridge, setSygnaBridge] = useState(false);
  const [sygnaGate, setSygnaGate] = useState(false);
  const [sygnaHub, setSygnaHub] = useState(false);
  const [sygnaEmailProtocol, setSygnaEmailProtocol] = useState(false);

  const [serverStatusUnknown, setServerStatusUnknown] = useState(false);
  const [serverStatusHealthy, setServerStatusHealthy] = useState(false);
  const [serverStatusUnhealthy, setServerStatusUnhealthy] = useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [approveLoading, setApproveLoading] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [informDialogOpen, setInformDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  const [reviewVaspMutation] = useMutation(REVIEW_VASP);
  const [validFormMutation] = useMutation(VALID_FORM);

  const [getReViewList, getReViewListResult] = useLazyQuery(GET_REVIEW_LIST, {
    variables: {
      status: l.values(OperationStatus),
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!data) {
        setErrorDialogOpen(true);
        setError(parseGraphQLErrorMessage('data is undefined'));
        return;
      }
      if (l.has(data, 'getReviewList')) {
        const reviewData = data.getReviewList.map((review) => {
          const checkable = isVaspCheckable(review, roleId);
          return {
            ...review,
            checkable,
            vasp_code: getVaspCode(review),
            registration_type_str: getRegistrationType(review.registration_type),
          };
        });
        setReviewList(reviewData);
      } else {
        setReviewList([]);
      }
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token && !checkJWTExpired(token)) {
      getReViewList();
    }
  }, []);

  const handleSelectAllClick = (event, tempVaspData) => {
    if (event.target.checked) {
      const newSelectedIds = tempVaspData.filter((data) => data.checkable).map((data) => data.id);
      setSelectedVaspIds(newSelectedIds);
      return;
    }
    setSelectedVaspIds([]);
  };

  const handleDeleteSelectionClick = () => {
    setSelectedVaspIds([]);
  };

  const handleEditActionClick = (rowData) => {
    const parameter = { id: rowData.id };
    navigate('/approve-vasp', { state: { parameter } });
  };

  const handleSelectionChange = (_, selectedId) => {
    const selectedIndex = selectedVaspIds.indexOf(selectedId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedVaspIds, selectedId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedVaspIds.slice(1));
    } else if (selectedIndex === selectedVaspIds.length - 1) {
      newSelected = newSelected.concat(selectedVaspIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedVaspIds.slice(0, selectedIndex),
        selectedVaspIds.slice(selectedIndex + 1),
      );
    }
    setSelectedVaspIds(newSelected);
  };

  const handleSubmit = (e) => {
    setFilterPanelOpen(false);
    e.preventDefault();
  };

  const showReviewSuccess = () => {
    setInformDialogOpen(true);
  };

  const showReviewFailed = (e) => {
    setErrorDialogOpen(true);
    setError(parseGraphQLErrorMessage(e));
  };

  const getReviewVaspMutation = (reviewRoleId) => {
    if (reviewRoleId === RoleId.ACCOUNT_MANAGER) {
      return validFormMutation;
    }
    return reviewVaspMutation;
  };

  const genConfirmMessage = () => {
    const selectedVaspToReview = reviewList.filter((review) => selectedVaspIds.includes(review.id));
    const vaspCodes = selectedVaspToReview.map((vasp) => vasp.vasp_code);
    return `You want to approve ${vaspCodes} ?`;
  };

  const handleApproveClick = async () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmClick = async () => {
    const selectedVaspToReview = reviewList.filter((review) => selectedVaspIds.includes(review.id));

    await reviewVasp({
      reviewVaspMutation: getReviewVaspMutation(roleId),
      selectedVaspData: selectedVaspToReview,
      operation: Operations.APPROVE,
      token,
      setButtonLoading: setApproveLoading,
      setButtonSuccess: setApproveSuccess,
      onSuccess: showReviewSuccess,
      onFailed: showReviewFailed,
    });
  };

  const filterSearchText = (o) => {
    if (!searchText || searchText.length === 0) {
      return true;
    }
    const keys = Object.keys(o);
    for (let i = 0; i < keys.length; i += 1) {
      if (
        keys[i] === 'vasp_code' ||
        keys[i] === 'vasp_name' ||
        keys[i] === 'vasp_email' ||
        keys[i] === 'account_expiration_dt'
      ) {
        if (typeof o[keys[i]] === 'string') {
          const value = o[keys[i]];
          if (value.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const filterRegistrationType = (o) => {
    if (!sygnaBridge && !sygnaEmailProtocol && !sygnaGate && !sygnaHub) {
      return true;
    }
    if (sygnaGate && o.registration_type === OperationRegistrationType.SygnaGate) {
      return true;
    }
    if (sygnaHub && o.registration_type === OperationRegistrationType.SygnaHub) {
      return true;
    }
    if (
      sygnaEmailProtocol &&
      o.registration_type === OperationRegistrationType.SygnaEmailProtocol
    ) {
      return true;
    }
    if (
      sygnaBridge &&
      o.registration_type !== OperationRegistrationType.SygnaGate &&
      o.registration_type !== OperationRegistrationType.SygnaHub &&
      o.registration_type !== OperationRegistrationType.SygnaEmailProtocol
    ) {
      return true;
    }
    return false;
  };

  const filterServerStatus = (o) => {
    if (!serverStatusHealthy && !serverStatusUnhealthy && !serverStatusUnknown) {
      return true;
    }
    if (serverStatusHealthy && o.vasp_server_status === VaspServerStatus.HEALTHY) {
      return true;
    }
    if (serverStatusUnhealthy && o.vasp_server_status === VaspServerStatus.UNHEALTHY) {
      return true;
    }
    if (serverStatusUnknown && o.vasp_server_status === VaspServerStatus.UNKNOWN) {
      return true;
    }

    return false;
  };

  const filterOperation = (o) => {
    if (!createOperation && !updateOperation && !deleteOperation) {
      return filterRegistrationType(o);
    }
    if (createOperation && o.operation === Operations.CREATE) {
      return filterRegistrationType(o);
    }

    if (updateOperation && o.operation === Operations.UPDATE) {
      return filterRegistrationType(o);
    }

    if (deleteOperation && o.operation === Operations.DELETE) {
      return filterRegistrationType(o);
    }

    if (returnOperation && o.operation === Operations.RETURN) {
      return filterRegistrationType(o);
    }

    if (resendOperation && o.operation === Operations.RESEND) {
      return filterRegistrationType(o);
    }

    return false;
  };
  let tempReviewList = l.filter(reviewList || [], filterSearchText);
  tempReviewList = l.filter(tempReviewList, (o) => {
    const isBetween = moment(o.go_live_dt).isBetween(
      selectedFromDate ? selectedFromDate.toISOString() : MIN_FROM_DATE,
      selectedToDate ? selectedToDate.toISOString() : MAX_TO_DATE,
    );
    const isBetweenExpired = moment(o.account_expiration_dt).isBetween(
      selectedExpiredFromDate ? selectedExpiredFromDate.toISOString() : MIN_FROM_DATE,
      selectedExpiredToDate ? selectedExpiredToDate.toISOString() : MAX_TO_DATE,
    );

    // go_live_dt is nullable
    if (o.go_live_dt !== null && !isBetween) return false;
    if (o.account_expiration_dt !== '' && !isBetweenExpired) return false;

    if (
      !pendingOnVasp &&
      !pendingOnAccManager &&
      !pendingOnApprover &&
      !approved &&
      !merged &&
      !inactive &&
      !expired
    ) {
      return filterOperation(o);
    }
    if (pendingOnVasp && o.vasp_status === OperationStatus.PendingOnVasp) {
      return filterOperation(o);
    }
    if (pendingOnAccManager && o.vasp_status === OperationStatus.PendingOnAcc) {
      return filterOperation(o);
    }
    if (pendingOnApprover && o.vasp_status === OperationStatus.PendingOnApprover) {
      return filterOperation(o);
    }
    if (approved && o.vasp_status === OperationStatus.Approved) {
      return filterOperation(o);
    }
    if (merged && o.vasp_status === OperationStatus.Merged) {
      return filterOperation(o);
    }
    if (inactive && o.vasp_status === OperationStatus.Inactive) {
      return filterOperation(o);
    }
    if (expired && o.vasp_status === OperationStatus.Expired) {
      return filterOperation(o);
    }
    return false;
  });
  tempReviewList = l.filter(tempReviewList, filterServerStatus);

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'Approve VASP']}
          breadcrumbLinks={['/', '/approve-vasp']}
        />
        <div style={{ maxWidth: '100%', marginTop: '2em' }}>
          <ApproveTable
            selectedVaspIds={selectedVaspIds}
            reviewList={tempReviewList || []}
            onEditActionClick={handleEditActionClick}
            onSelectAllClick={(e) => {
              handleSelectAllClick(e, tempReviewList);
            }}
            onSelectionChange={handleSelectionChange}
            onDeleteSelectionClick={handleDeleteSelectionClick}
            searchText={{ get: searchText, set: setSearchText }}
            setFilterPanelOpen={setFilterPanelOpen}
            pendingOnVasp={{ set: setPendingOnVasp, get: pendingOnVasp }}
            pendingOnAccManager={{ set: setPendingOnAccManager, get: pendingOnAccManager }}
            pendingOnApprover={{ set: setPendingOnApprover, get: pendingOnApprover }}
            approved={{ set: setApproved, get: approved }}
            merged={{ set: setMerged, get: merged }}
            inactive={{ set: setInactive, get: inactive }}
            expired={{ set: setExpired, get: expired }}
            fromDate={{ set: setFromDate, get: selectedFromDate }}
            toDate={{ set: setToDate, get: selectedToDate }}
            expiredFromDate={{ set: setExpiredFromDate, get: selectedExpiredFromDate }}
            expiredToDate={{ set: setExpiredToDate, get: selectedExpiredToDate }}
            createOperation={{ set: setCreateOperation, get: createOperation }}
            updateOperation={{ set: setUpdateOperation, get: updateOperation }}
            deleteOperation={{ set: setDeleteOperation, get: deleteOperation }}
            returnOperation={{ set: setReturnOperation, get: returnOperation }}
            resendOperation={{ set: setResendOperation, get: resendOperation }}
            sygnaBridge={{ set: setSygnaBridge, get: sygnaBridge }}
            sygnaGate={{ set: setSygnaGate, get: sygnaGate }}
            sygnaHub={{ set: setSygnaHub, get: sygnaHub }}
            sygnaEmailProtocol={{ set: setSygnaEmailProtocol, get: sygnaEmailProtocol }}
            serverStatusUnknown={{ set: setServerStatusUnknown, get: serverStatusUnknown }}
            serverStatusUnhealthy={{ set: setServerStatusUnhealthy, get: serverStatusUnhealthy }}
            serverStatusHealthy={{ set: setServerStatusHealthy, get: serverStatusHealthy }}
            loading={getReViewListResult.loading}
          />

          <div style={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              id="approve_button"
              buttonText="Approve"
              loading={approveLoading}
              done={{ get: approveSuccess, set: setApproveSuccess }}
              disabled={selectedVaspIds.length === 0 || approveLoading}
              onClick={(e) => handleApproveClick(e)}
              timeout={3000}
            />
          </div>
        </div>

        <FilterDialog
          open={filterPanelOpen}
          setOpen={setFilterPanelOpen}
          onSubmit={handleSubmit}
          pendingOnVasp={{ set: setPendingOnVasp, get: pendingOnVasp }}
          pendingOnAccManager={{ set: setPendingOnAccManager, get: pendingOnAccManager }}
          pendingOnApprover={{ set: setPendingOnApprover, get: pendingOnApprover }}
          approved={{ set: setApproved, get: approved }}
          merged={{ set: setMerged, get: merged }}
          inactive={{ get: inactive, set: setInactive }}
          expired={{ get: expired, set: setExpired }}
          fromDate={{ set: setFromDate, get: selectedFromDate }}
          toDate={{ set: setToDate, get: selectedToDate }}
          expiredFromDate={{ set: setExpiredFromDate, get: selectedExpiredFromDate }}
          expiredToDate={{ set: setExpiredToDate, get: selectedExpiredToDate }}
          createOperation={{ set: setCreateOperation, get: createOperation }}
          updateOperation={{ set: setUpdateOperation, get: updateOperation }}
          deleteOperation={{ set: setDeleteOperation, get: deleteOperation }}
          returnOperation={{ set: setReturnOperation, get: returnOperation }}
          resendOperation={{ set: setResendOperation, get: resendOperation }}
          sygnaBridge={{ set: setSygnaBridge, get: sygnaBridge }}
          sygnaGate={{ set: setSygnaGate, get: sygnaGate }}
          sygnaHub={{ set: setSygnaHub, get: sygnaHub }}
          sygnaEmailProtocol={{ set: setSygnaEmailProtocol, get: sygnaEmailProtocol }}
          serverStatusUnknown={{ set: setServerStatusUnknown, get: serverStatusUnknown }}
          serverStatusUnhealthy={{ set: setServerStatusUnhealthy, get: serverStatusUnhealthy }}
          serverStatusHealthy={{ set: setServerStatusHealthy, get: serverStatusHealthy }}
        />

        <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
        <CustomDialog
          open={{ get: confirmDialogOpen, set: setConfirmDialogOpen }}
          title="Confirm"
          message={genConfirmMessage()}
          positiveButtonText="ok"
          onPositiveButtonClick={handleConfirmClick}
          negativeButtonText="cancel"
          onClose={() => {
            setConfirmDialogOpen(false);
          }}
        />
        <CustomDialog
          open={{ get: informDialogOpen, set: setInformDialogOpen }}
          title="Inform"
          message="approve success"
          positiveButtonText="ok"
          onClose={() => {
            setInformDialogOpen(false);
            setSelectedVaspIds([]);
            getReViewList();
            setApproveSuccess(false);
          }}
          timeout={5000}
        />
      </AuthenticatedPage>
    </Layout>
  );
}

export default ApproveVASPList;
