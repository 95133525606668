import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';
import { Edit, Visibility, Send } from '@material-ui/icons';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { OperationStatus, OperationStatusContents } from '../../../constants';
import { getToken, getRoleId, isVaspEditableByRole } from '../../../utils/utils';

function desc(a, b, orderBy) {
  const aValue = a[orderBy] || '';
  const bValue = b[orderBy] || '';

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (cmp) {
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  }
  const sortedData = stabilizedThis.map((el) => el[0]);
  return sortedData;
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const calEmptyRows = (rows, page, rowsPerPage) =>
  rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

const renderEmptyTableBody = (headCells) => (
  <TableBody>
    <TableRow style={{ height: 300 }} key={`empty-${0}`}>
      {/* <TableCell style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'center' }}
          colSpan={props.headCells.length + 1}
          key="empty-"
          width={'100%'}>
          No records to display
        </TableCell> */}
      <TableCell
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          textAlign: 'center',
        }}
        colSpan={headCells.length + 1}
        width="100%"
        key="empty-"
      >
        No records to display
      </TableCell>
    </TableRow>
  </TableBody>
);

const renderActionIcons = (row, roleId, onEditActionClick, index) => {
  const isEditable = isVaspEditableByRole(row, roleId);
  if (!isEditable) {
    return (
      <Tooltip title="visibility">
        <IconButton
          id={`ActionIcon_${index}`}
          name="visibility"
          onClick={() => {
            onEditActionClick(row);
          }}
        >
          <Visibility />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <>
      <Tooltip title="edit">
        <IconButton
          id={`ActionIcon_${index}`}
          name="edit"
          onClick={() => {
            onEditActionClick(row);
          }}
        >
          <Edit />
        </IconButton>
      </Tooltip>

      {row.vasp_status === OperationStatus.Expired ? (
        <Tooltip title="send">
          <IconButton
            id={`ActionIcon_${index}`}
            name="send"
            onClick={() => {
              onEditActionClick(row);
            }}
          >
            <Send />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

const renderSkeletonTableBody = () => (
  <TableBody>
    {[0, 1, 2, 3, 4].map((index) => (
      <TableRow key={`empty-${index}`}>
        <TableCell>
          <Checkbox disabled checked={false} />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

const renderDataTableBody = (data) => {
  const {
    rows,
    page,
    rowsPerPage,
    order,
    orderBy,
    isItemSelected,
    onSelectionChange,
    onEditActionClick,
    headCells,
    roleId,
  } = data;
  const emptyRows = calEmptyRows(rows, page, rowsPerPage);

  return (
    <TableBody>
      {stableSort(rows, getSorting(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const checked = isItemSelected(row.id);
          const labelId = `approve-table-checkbox-${index}`;
          return (
            <TableRow hover key={row.id}>
              <TableCell component="th" id={labelId} scope="row" width={headCells[0].width}>
                <div>
                  <Checkbox
                    id={`checkbox_${index}`}
                    disabled={!row.checkable}
                    checked={checked}
                    onChange={(event) => {
                      onSelectionChange(event, row.id);
                    }}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                  {renderActionIcons(row, roleId, onEditActionClick, index)}
                </div>
              </TableCell>

              <TableCell align="left" width={headCells[1].width}>
                {row.operation}
              </TableCell>
              <TableCell align="left" width={headCells[2].width}>
                {row.vasp_email}
              </TableCell>
              <TableCell align="left" width={headCells[3].width}>
                {row.registration_type_str}
              </TableCell>
              <TableCell align="left" width={headCells[4].width}>
                {row.vasp_code}
              </TableCell>
              <TableCell align="left" width={headCells[5].width}>
                {row.vasp_name}
              </TableCell>
              <TableCell
                align="left"
                width={headCells[6].width}
                style={{
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
              >
                {row.vasp_server_status}
              </TableCell>
              <TableCell align="left" width={headCells[7].width} id={`go_live_dt_${index}`}>
                {row.go_live_dt && moment(row.go_live_dt).format('YYYY/MM/DD HH:mm')}
              </TableCell>
              <TableCell
                align="left"
                width={headCells[8].width}
                id={`account_expiration_dt_${index}`}
              >
                {row.account_expiration_dt &&
                  moment(row.account_expiration_dt).format('YYYY/MM/DD HH:mm')}
              </TableCell>
              <TableCell
                align="left"
                width={headCells[9].width}
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {OperationStatusContents[row.vasp_status]}
              </TableCell>
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell width="100%" colSpan={headCells.length} />
        </TableRow>
      )}
    </TableBody>
  );
};

const ApproveTableBody = (props) => {
  const { rows, loading } = props;
  if (loading) {
    return renderSkeletonTableBody();
  }
  if (rows.length === 0) {
    return renderEmptyTableBody(props.headCells);
  }
  const token = getToken();
  const roleId = getRoleId(token);
  return renderDataTableBody({ ...props, roleId });
};

ApproveTableBody.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      operation: PropTypes.string,
      vasp_code_prefix: PropTypes.string,
      country_code: PropTypes.string,
      city_code: PropTypes.string,
      vasp_name: PropTypes.string,
      go_live_dt: PropTypes.string,
      vasp_status: PropTypes.string,
      checkable: PropTypes.bool,
      vasp_code: PropTypes.string,
      email: PropTypes.string,
      registration_type_str: PropTypes.string,
      account_expiration_dt: PropTypes.string,
      vasp_server_status: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  isItemSelected: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onEditActionClick: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      label: PropTypes.string,
      disablePadding: PropTypes.bool,
      width: PropTypes.string,
    }),
  ).isRequired,
};
export default ApproveTableBody;
