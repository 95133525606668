/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Paper, Table, TableContainer, TablePagination } from '@material-ui/core';
import ApproveTableToolbar from './ApproveTableToolbar';
import ApproveTableHead from './ApproveTableHead';
import ApproveTableBody from './ApproveTableBody';
import { useStyles, useToolbarStyles } from './styles';
import FilterDisplayer from '../filter-displayer';

function ApproveTable(props) {
  const {
    selectedVaspIds,
    reviewList,
    onSelectAllClick,
    onSelectionChange,
    onEditActionClick,
    onDeleteSelectionClick,
    searchText,
    setFilterPanelOpen,
    pendingOnVasp,
    pendingOnAccManager,
    pendingOnApprover,
    approved,
    merged,
    expired,
    inactive,
    fromDate,
    toDate,
    expiredFromDate,
    expiredToDate,
    createOperation,
    updateOperation,
    deleteOperation,
    returnOperation,
    resendOperation,
    loading,
    sygnaBridge,
    sygnaGate,
    sygnaHub,
    sygnaEmailProtocol,
    serverStatusUnknown,
    serverStatusHealthy,
    serverStatusUnhealthy,
  } = props;

  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('go_live_dt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isItemSelected = (id) => selectedVaspIds.indexOf(id) !== -1;

  const validateData = reviewList.filter((data) => data.checkable);

  const genHeadCells = () => [
    { id: 'icons', numeric: false, label: '', disablePadding: true, width: '10%' },
    {
      id: 'action_type',
      numeric: false,
      label: 'Action Type',
      disablePadding: false,
      width: '10%',
    },
    { id: 'email', numeric: false, label: 'Email', disablePadding: false, width: '15%' },
    {
      id: 'registration_type',
      numeric: false,
      label: 'Registration Type',
      disablePadding: false,
      width: '10%',
    },
    { id: 'vasp_code', numeric: false, label: 'VASP code', disablePadding: false, width: '10%' },
    { id: 'vasp_name', numeric: false, label: 'VASP name', disablePadding: false, width: '20%' },
    {
      id: 'vasp_server_status',
      numeric: false,
      label: 'VASP server health status',
      disablePadding: false,
      width: '10%',
    },
    {
      id: 'go_live_timestamp',
      numeric: false,
      label: 'Go-live timestamp',
      disablePadding: false,
      width: '10%',
    },
    {
      id: 'account_expiration_dt_timestamp',
      numeric: false,
      label: 'Expiration Date',
      disablePadding: false,
      width: '10%',
    },
    { id: 'status', numeric: false, label: 'Status', disablePadding: false, width: '15%' },
  ];

  const headCells = genHeadCells();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ApproveTableToolbar
          classes={useToolbarStyles()}
          numSelected={selectedVaspIds.length}
          searchText={searchText}
          onDeleteSelectionClick={onDeleteSelectionClick}
        />

        <FilterDisplayer
          onOpenFilterClicked={() => setFilterPanelOpen(true)}
          pendingOnVasp={{ set: pendingOnVasp.set, get: pendingOnVasp.get }}
          pendingOnAccManager={{ set: pendingOnAccManager.set, get: pendingOnAccManager.get }}
          pendingOnApprover={{ set: pendingOnApprover.set, get: pendingOnApprover.get }}
          approved={{ set: approved.set, get: approved.get }}
          merged={{ set: merged.set, get: merged.get }}
          inactive={{ set: inactive.set, get: inactive.get }}
          expired={{ set: expired.set, get: expired.get }}
          fromDate={{ set: fromDate.set, get: fromDate.get }}
          toDate={{ set: toDate.set, get: toDate.get }}
          expiredFromDate={{ set: expiredFromDate.set, get: expiredFromDate.get }}
          expiredToDate={{ set: expiredToDate.set, get: expiredToDate.get }}
          createOperation={{ set: createOperation.set, get: createOperation.get }}
          updateOperation={{ set: updateOperation.set, get: updateOperation.get }}
          deleteOperation={{ set: deleteOperation.set, get: deleteOperation.get }}
          returnOperation={{ set: returnOperation.set, get: returnOperation.get }}
          resendOperation={{ set: resendOperation.set, get: resendOperation.get }}
          sygnaBridge={{ set: sygnaBridge.set, get: sygnaBridge.get }}
          sygnaGate={{ set: sygnaGate.set, get: sygnaGate.get }}
          sygnaHub={{ set: sygnaHub.set, get: sygnaHub.get }}
          sygnaEmailProtocol={{ set: sygnaEmailProtocol.set, get: sygnaEmailProtocol.get }}
          serverStatusUnknown={{
            set: serverStatusUnknown.set,
            get: serverStatusUnknown.get,
          }}
          serverStatusHealthy={{
            set: serverStatusHealthy.set,
            get: serverStatusHealthy.get,
          }}
          serverStatusUnhealthy={{
            set: serverStatusUnhealthy.set,
            get: serverStatusUnhealthy.get,
          }}
        />

        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="approve table"
          >
            <ApproveTableHead
              headCells={headCells}
              classes={classes}
              numSelected={selectedVaspIds.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={onSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={validateData.length}
            />
            <ApproveTableBody
              headCells={headCells}
              rows={reviewList}
              page={page}
              rowsPerPage={rowsPerPage}
              order={order}
              orderBy={orderBy}
              isItemSelected={isItemSelected}
              onSelectionChange={onSelectionChange}
              onEditActionClick={onEditActionClick}
              loading={loading}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={reviewList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

ApproveTable.propTypes = {
  selectedVaspIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  reviewList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      operation: PropTypes.string,
      vasp_code_prefix: PropTypes.string,
      country_code: PropTypes.string,
      city_code: PropTypes.string,
      vasp_name: PropTypes.string,
      go_live_dt: PropTypes.string,
      vasp_status: PropTypes.string,
      checkable: PropTypes.bool,
      vasp_code: PropTypes.string,
      email: PropTypes.string,
      registration_type_str: PropTypes.string,
      account_expiration_dt: PropTypes.string,
    }),
  ).isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onEditActionClick: PropTypes.func.isRequired,
  onDeleteSelectionClick: PropTypes.func.isRequired,
  searchText: PropTypes.shape({
    get: PropTypes.string,
    set: PropTypes.func,
  }).isRequired,
  setFilterPanelOpen: PropTypes.func.isRequired,
  pendingOnVasp: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnAccManager: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  pendingOnApprover: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  approved: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  merged: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  inactive: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expired: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  fromDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  toDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expiredFromDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  expiredToDate: PropTypes.shape({
    get: PropTypes.object,
    set: PropTypes.func.isRequired,
  }).isRequired,
  createOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  updateOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  deleteOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  returnOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  resendOperation: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaBridge: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaGate: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaHub: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  sygnaEmailProtocol: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusUnknown: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusHealthy: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  serverStatusUnhealthy: PropTypes.shape({
    get: PropTypes.bool.isRequired,
    set: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ApproveTable;
